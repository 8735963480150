(function () {

    'use strict';

    module.exports = PrescriptionListCtrl;

    PrescriptionListCtrl.$inject = ["prescriptionService", "$transition$", "system", "$q", "$scope"];

    function PrescriptionListCtrl(service, $transition$, system, $q, $scope) {
        let vm = this;

        let dateFormat = system['date_format'].js;
        let currentPatient = _.get($transition$.params('to'), 'pId');

        vm.$onInit = init;

        function init() {
            service.prescriptionType.next(null);
            service.itemActive.next({id: -1, type: null});
            service.itemEdited.next({id: -1, type: null});
            service.itemDuplicated.next({id: -1, type: null});

            vm.promise = $q.all([
                service.getTextPrescriptions(currentPatient),
                service.getMedicalPrescriptions(currentPatient),
                service.getBiologyPrescriptions(currentPatient),
                service.getProcedurePrescriptions(currentPatient)
            ]).then(success);


            function success(results) {
                vm.list = sortPrescription(_.flatten(results));
            }

            let itemDeletedSubscription = service.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(item) {
                _.remove(vm.list, item);
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
            }
        }

        function sortPrescription(list) {
            return _.sortBy(list, function (o) {
                o.constructed_title = service.constructedTitle(o);
                return -1 * parseFloat(moment(o['prescription_date'], dateFormat).valueOf() + '.' + moment(o['created_at']).valueOf())
            });
        }
    }
})();
