(function () {

    'use strict';

    module.exports = BiologyPrescriptionFormCtrl;

    BiologyPrescriptionFormCtrl.$inject = ["system", "prescriptionService", "$transition$", "$mdDialog", "$q", "$scope", "textEditorService", "$translate"];

    function BiologyPrescriptionFormCtrl(system, prescriptionService, $transition$, $mdDialog, $q, $scope, textEditorService, $translate) {
        let vm = this;
        let dateFormat = system['date_format'].js;

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
        let currentRequest = _.get($transition$.params('to'), 'requestId');
        let currentCopy = _.get($transition$.params('to'), 'copy');
        let legacy = !!_.get($transition$.params('to'), 'legacy');

        vm.editorConfigKey = "biology_prescription";
        vm.editorOpts = {};

        vm.commentField = {
            meta_data: {},
            label: $translate.instant('comment'),
        }

        vm.$onInit = init;
        vm.submit = submit;
        vm.saveModel = saveModel;
        vm.prePrint = prePrint;
        vm.signPrescription = signPrescription;
        vm.unlockPrescription = unlockPrescription;

        vm.cancel = goBack;
        vm.deleteItem = prescriptionService.deleteItem;

        function init() {
            let promise = [];

            prescriptionService.prescriptionType.next({type: 'BiologyPrescription'});
            prescriptionService.fileManager.next(false);
            prescriptionService.itemActive.next({id: -1, type: null});
            prescriptionService.itemDuplicated.next({id: -1, type: null});
            prescriptionService.itemEdited.next({id: currentPrescription, type: 'BiologyPrescription'});

            vm.editorName = _.uniqueId("biology-prescription-");
            vm.showFileManager = false;

            if (!_.isNull(currentCopy)) {
                prescriptionService.itemDuplicated.next({id: currentCopy, type: 'BiologyPrescription'});
                promise.push(prescriptionService.getBiologyPrescription(currentCopy))
            } else if (!_.isNull(currentPrescription)) {
                if (legacy) {
                    promise.push(prescriptionService.getLegacyBiologyPrescription(currentPrescription));
                } else {
                    promise.push(prescriptionService.getBiologyPrescription(currentPrescription, !_.isNull(currentRequest)));
                }
            } else if (!_.isNull(currentRequest)) promise.push(prescriptionService.getBiologyPrescription(currentRequest));
            else promise.push(createEmptyBiologyPrescription());

            vm.patient = currentPatient;
            vm.isResult = false;
            vm.editModel = false;

            let itemDeletedSubscription = prescriptionService.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(item) {
                if (_.isEqual(item, {
                    id: currentPrescription,
                    prescription_type: 'BiologyPrescription'
                })
                ) prescriptionService.listState();
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                fileManagerSubscription.unsubscribe();
            }

            vm.promise = $q.all(promise).then(success);

            function success(data) {
                let actualData = _.head(data);

                vm.prescription = !_.isNull(currentCopy) || (!_.isNull(currentRequest) && _.isNull(currentPrescription))
                    ? _.assign(_.pick(actualData, ['details', 'comment', 'owner_name']), {
                        visit: {id: currentVisit},
                        prescription_date: moment().format(dateFormat)
                    })
                    : actualData;

                if (_.isNull(currentCopy)) {
                    vm.editModel = actualData['is_model'];

                    prescriptionService.prescriptionType.next({
                        type: 'BiologyPrescription',
                        model: vm.editModel
                    })
                }

                if (!_.isNull(currentRequest)) {
                    vm.isResult = true;
                    vm.prescription.request = {id: currentRequest};
                    prescriptionService.getResult(currentRequest).then(resultCallback);
                }
            }

            function resultCallback(data) {
                if (_.isNil(data)) return;

                vm.prescription.details = _.reduce(vm.prescription.details, function (result, detail) {
                    if (!_.isUndefined(data[detail.exam.id])) {
                        if (detail.exam['is_unique']) detail.value = data[detail.exam.id];
                        else detail.value = _.merge(detail.value, data[detail.exam.id])
                    }

                    result.push(detail);

                    return result;
                }, []);
            }

            function createEmptyBiologyPrescription() {
                return {
                    prescription_date: moment().format(dateFormat),
                    details: [],
                    comment: "",
                    visit: {id: currentVisit}
                }
            }

            prescriptionService.useBiologyModel.subscribe(useBiologyModel);

            function useBiologyModel(data) {
                if (vm.isResult) return;

                if (_.isObject(data) && !_.isUndefined(data.exam)) {
                    insertDetailFromModel(data);
                } else {
                    vm.promise = prescriptionService.getBiologyModelPrescription(data)
                        .then(success);
                }

                function success(data) {
                    _.forEach(data.details, insertDetailFromModel);
                }
            }

            let fileManagerSubscription = prescriptionService.fileManager.subscribe(next => {
                vm.showFileManager = !!next;
            });
        }

        function insertDetailFromModel(newDetail) {
            let detail = _.find(vm.prescription.details, {exam: {id: newDetail.exam.id}});

            if (_.isUndefined(detail)) {
                // exam not found in current prescription
                vm.prescription.details.push(newDetail);
            } else {
                // exam found
                detail.value = _.assign({}, detail.value, newDetail.value);
                detail.last_value = _.assign({}, detail.value, newDetail.value);
            }
        }

        function submit(quit) {
            /*let record = textEditorService.getEditor(vm.editorName).getContent();
            if (_.isNil(vm.prescription.record) || vm.prescription.record != record)
                vm.prescription.record = record;*/


            if (vm.editModel) return prescriptionService.saveBiologyPrescription(vm.prescription, currentPatient).then(_.bind(modelSuccess, quit));
            else if (_.isNull(currentRequest)) return saveRequest(quit);
            else return saveResult(quit);
        }

        function saveResult(quit) {
            vm.prescription.details = _.reduce(vm.prescription.details, function (result, detail) {
                if (detail.exam['is_unique'] && !_.isNull(detail.value)) {
                    result.push(detail);
                }
                if (!detail.exam['is_unique']) {
                    _.forEach(detail.value, function (value, uid) {
                        if (_.isNull(value)) _.unset(detail.value, uid);
                    });

                    if (!_.isEmpty(detail.value)) result.push(detail);
                }
                return result;
            }, []);

            return prescriptionService.saveBiologyPrescriptionResult(vm.prescription, currentPatient)
                .then(data => saveCallback(data, quit));
        }

        function saveRequest(quit) {
            return prescriptionService.saveBiologyPrescriptionRequest(vm.prescription, currentPatient)
                .then(data => saveCallback(data, quit));
        }

        function saveCallback(data, quit) {
            if (vm.editModel) prescriptionService.modelAdded.next(data);
            else prescriptionService.itemAdded.next(data);

            if (quit === "list") return prescriptionService.listState();
            if (quit === "back") return goBack();

            if (_.isNull(currentPrescription)) {
                vm.prescription = data;

                currentPrescription = data.id;
                prescriptionService.refreshState(data);
            }
        }

        function saveModel($event) {
            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.BiologyPrescription.title_validation')
                .placeholder('title')
                .targetEvent($event);

            $mdDialog.show(confirm).then(success);

            function success(data) {
                let model = _.assign(_.pick(vm.prescription, ['details', 'comment']), {title: data});
                prescriptionService.saveBiologyPrescription(model)
                    .then(modelSuccess);
            }
        }

        function prePrint() {
            const deferred = $q.defer();
            const editor = textEditorService.getEditor(vm.editorName);
            const success = () => {
                submit()
                    .then(() => {
                        deferred.resolve(false);
                    });
            }

            if (editor) {
                editor.compileContent()
                    .then(() => success());
            } else {
                success();
            }

            return deferred.promise;
        }

        function modelSuccess(data) {
            prescriptionService.modelAdded.next(data);
            if (_.isBoolean(this) && this) return prescriptionService.listState();
        }

        function goBack() {
            return window.history.back();
        }

        function toggleLock(lock) {
            vm.prescription.is_locked = lock;

            return vm.prePrint();
        }

        function signPrescription() {
            return toggleLock(true);
        }

        function unlockPrescription() {
            return toggleLock(false);
        }

    }

})();
