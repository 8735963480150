/**
 * Created by Issmahane  26/12/2016
 */
(function () {
    'use strict';

    var mnProcedureTable = {
        controller: mnProcedureTableCtrl,
        controllerAs: "vm",
        bindings: {
            details: '=',
            readOnly: "<",
            hasPrice: "<"
        },
        template: require('shared/views/procedure-table.tpl.html'), // or template,
    };

    mnProcedureTableCtrl.$inject = [];

    function mnProcedureTableCtrl() {
        let vm = this;
        vm.$onInit = init;

        vm.deleteDetail = deleteDetail;

        function init() {

        }

        function deleteDetail(index) {
            vm.details.splice(index, 1);
        }

    }

    module.exports = mnProcedureTable;

})();
