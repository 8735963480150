(function () {

    'use strict';

    module.exports = PrescriptionContainerCtrl;

    PrescriptionContainerCtrl.$inject = ["$q", "$state", "prescriptionService", "$transition$", "$scope", "$transitions", "visitService", "$mdDialog", "observationService"];

    function PrescriptionContainerCtrl($q, $state, prescriptionService, $transition$, $scope, $transitions, visitService, $mdDialog, observationService) {
        let vm = this;
        let currentPatient = _.get($transition$.params('to'), 'pId');

        let models = [];
        let prescriptions = [];
        let biologyResumeDialog = require('../dialogs/biology-resume-dialog');

        vm.$onInit = init;

        vm.newPrescription = newPrescription;

        vm.viewPrescription = viewPrescription;
        vm.pushNewModelPrescription = pushNewModelPrescription;

        vm.editItem = editItem;
        vm.duplicateItem = duplicateItem;
        vm.deleteItem = deleteItem;
        vm.listState = prescriptionService.listState;
        vm.printGroup = prescriptionService.printGroup;

        vm.addResult = addResult;
        vm.toggleModels = toggleModels;
        vm.toggleBiologyExamTree = toggleBiologyExamTree;

        vm.showBiologyResume = showBiologyResume;
        vm.useBiologyModelPrescription = useBiologyModelPrescription;

        vm.addMedicalPrescription = addMedicalPrescription;
        vm.addProcedurePrescription = addProcedurePrescription;
        vm.addTextPrescription = addTextPrescription;

        vm.toggleFileManager = toggleFileManager;

        vm.returnToObservation = id => observationService.returnTo(id);

        function init() {
            vm.lock = true;
            vm.showModel = false;
            vm.showBiologyExamTree = false;
            vm.showFileManager = false;
            vm.prescriptionType = null;
            vm.currentVisit = _.get($transition$.params('to'), 'visitId');
            vm.currentDuplicated = vm.currentPrescription = vm.currentEditPrescription = {id: -1, type: null};

            if (_.eq($state.current.name, "app.visit.prescription")) vm.listState();

            let listener = $transitions.onBefore({}, toList);

            function toList(transition) {
                let state = transition.to();

                if (!_.eq(state.name, "app.visit.prescription.list") && _.eq(state.name, "app.visit.prescription")) {
                    vm.listState();
                    return false;
                }

                return true;
            }

            vm.promise = $q.all([
                prescriptionService.getTextPrescriptionList(currentPatient),
                prescriptionService.getMedicalPrescriptionList(currentPatient),
                prescriptionService.getBiologyPrescriptionList(currentPatient),
                prescriptionService.getProcedurePrescriptionList(currentPatient),

                prescriptionService.getModelTextPrescriptionList(),
                prescriptionService.getModelMedicalPrescriptionList(),
                prescriptionService.getModelBiologyPrescriptionList(),
                prescriptionService.getModelProcedurePrescriptionList()
            ]).then(success);

            function success(results) {
                prescriptions = sortPrescription(_(results).slice(0, 4).flatten().value());
                models = sortPrescription(_(results).slice(4).flatten().value());

                vm.list = filterPrescriptions();
            }

            let itemDeletedSubscription = prescriptionService.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(item) {
                _.remove(vm.list, item);
                _.remove(prescriptions, item);
                _.remove(models, item);
            }

            let itemEditedSubscription = prescriptionService.itemEdited.subscribe(itemEdited);

            function itemEdited(item) {
                vm.currentEditPrescription = item;
            }

            let itemActiveSubscription = prescriptionService.itemActive.subscribe(itemActive);

            function itemActive(item) {
                vm.currentPrescription = item;
            }

            let itemDuplicatedSubscription = prescriptionService.itemDuplicated.subscribe(itemDuplicated);

            function itemDuplicated(item) {
                vm.currentDuplicated = item;
            }

            let itemAddedSubscription = prescriptionService.itemAdded.subscribe(itemAdded);

            function itemAdded(item) {
                let index = _.findIndex(prescriptions, _.pick(item, ['id', 'prescription_type']));

                if (index === -1) {
                    prescriptions = _.concat(prescriptions, item);
                } else {
                    item.constructed_title = item.prescription_date;
                    prescriptions.splice(index, 1, item);
                }

                prescriptions = sortPrescription(prescriptions);
                vm.list = filterPrescriptions();

                vm.showModel = false;
            }

            let modelAddedSubscription = prescriptionService.modelAdded.subscribe(modelAdded);

            function modelAdded(item) {
                let index = _.findIndex(models, _.pick(item, ['id', 'prescription_type']));

                if (index === -1) models = sortPrescription(_.concat(models, item));
                else {
                    item.constructed_title = item.title;
                    models.splice(index, 1, item);
                }

                vm.showModel = true;
                vm.list = filterModels();
            }

            let prescriptionTypeSubscription = prescriptionService.prescriptionType.subscribe(prescriptionType);

            function prescriptionType(data) {
                vm.prescriptionType = _.get(data, 'type', null);
                vm.showBiologyExamTree = false;

                if (vm.showModel || _.get(data, 'model', false)) {
                    vm.showModel = true;
                    vm.list = filterModels();
                } else {
                    vm.showModel = false;
                    vm.list = filterPrescriptions();
                }
            }

            let currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);

            function handlePatient(data) {
                vm.patient = data;
            }

            let fileManagerSubscription = prescriptionService.fileManager.subscribe(handleFileManager);

            function handleFileManager(next) {
                vm.showFileManager = !!next;
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                itemEditedSubscription.unsubscribe();
                itemActiveSubscription.unsubscribe();
                itemDuplicatedSubscription.unsubscribe();
                itemAddedSubscription.unsubscribe();
                modelAddedSubscription.unsubscribe();
                prescriptionTypeSubscription.unsubscribe();
                currentPatientSubscription.unsubscribe();
                fileManagerSubscription.unsubscribe();

                listener();
            }


            //observation section
            vm.observationId = _.get($transition$.params('to'), 'observationId');
            if (!_.isNil(vm.observationId)) vm.observationContext = true;
        }

        function toggleBiologyExamTree() {
            vm.showBiologyExamTree = !vm.showBiologyExamTree;
            vm.showModel = false;
        }

        function toggleModels() {
            vm.showBiologyExamTree = false;
            vm.showModel = !vm.showModel;

            if (vm.showModel) vm.list = filterModels();
            else vm.list = filterPrescriptions();
        }

        function filterModels() {
            if (_.isNil(vm.prescriptionType)) return models;

            else return _.filter(models, function (item) {
                return item['prescription_type'] == vm.prescriptionType;
            });
        }

        function filterPrescriptions() {
            if (_.isNil(vm.prescriptionType)) return prescriptions;

            else return _.filter(prescriptions, {'prescription_type': vm.prescriptionType});
        }

        function showModels(show) {
            if (show) {
                vm.showModel = true;
                vm.list = filterModels();
            } else {
                vm.showModel = false;
                vm.list = filterPrescriptions();
            }
        }

        function pushNewModelPrescription(item) {
            let index = _.findIndex(models, {id: item.id});

            if (index === -1) models.push(item);
            else models.splice(index, 1, item);

            models = sortModelPrescription(models);
            showModels(true);
        }

        function sortModelPrescription(list) {
            return _.sortBy(list, function (item) {
                return _.chain(item.title).deburr().toLower().value();
            });
        }

        function viewPrescription(item, reload = true) {
            if (item['is_model']) prescriptionService.duplicateState(item.id, item['prescription_type']);
            else if (item.visit_id === vm.currentVisit) prescriptionService.editState(item, reload);
            else {
                $state.go("app.visit.prescription.view", {
                    type: item['prescription_type'],
                    prescriptionId: item.id,
                    legacy: item.is_legacy ? 1 : null,
                }, {inherit: true}).then($state.reload);
            }
        }


        function sortPrescription(list) {
            return _.sortBy(list, function (o) {
                o.constructed_title = o['is_model'] ? o.title : o.prescription_date;
                return o['is_model'] ? o.title : -1 * o['sort_date'];
            });
        }

        function newPrescription(type) {
            const url = `app.visit.prescription.${_.kebabCase(type)}`;
            prescriptionService.fileManager.next(false);

            $state.go(url, {
                copy: null,
                prescriptionId: null,
            }, {inherit: true}).then(_.bind(reloadState, null, url, $state.is(url)));
        }

        function reloadState(url, reload) {
            reload ? $state.reload(url) : _.noop();
            showModels(false);
        }

        function showBiologyResume(ev) {
            $mdDialog.show(_.assign(biologyResumeDialog, {
                targetEvent: ev,
                locals: {
                    currentPatient: currentPatient
                }
            }))
        }

        function useBiologyModelPrescription(item) {
            prescriptionService.useBiologyModel.next(item.id);
        }

        function editItem(item) {
            let isList = _.includes(["app.visit.prescription.list", "app.visit.prescription.view"], $state.current.name);
            prescriptionService.editState(item, !isList);
        }

        function duplicateItem(item) {
            vm.currentEditPrescription = {id: -1, type: null};
            vm.currentPrescription = {id: -1, type: null};
            let isList = _.includes(["app.visit.prescription.list", "app.visit.prescription.view"], $state.current.name);
            prescriptionService.duplicateState(item.id, item['prescription_type'], !isList);
        }

        function deleteItem(item) {
            prescriptionService.deleteItem(item);
        }

        function addResult(item) {
            $state.go("app.visit.prescription.biology-prescription", {
                prescriptionId: null,
                copy: null,
                requestId: item.id
            }, {inherit: true}).then($state.reload);
        }


        function addMedicalPrescription(item) {
            prescriptionService.addMedicalPrescriptionSubject.next(item.id);
        }

        function addProcedurePrescription(item) {
            prescriptionService.addProcedurePrescriptionSubject.next(item.id);
        }

        function addTextPrescription(item) {
            prescriptionService.addTextPrescriptionSubject.next(item.id);
        }

        function toggleFileManager() {
            vm.showFileManager = !vm.showFileManager;

            prescriptionService.fileManager.next(vm.showFileManager);
        }
    }


})();