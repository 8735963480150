(function () {

    'use strict';

    module.exports = TextPrescriptionFormCtrl;

    TextPrescriptionFormCtrl.$inject = ["system", "prescriptionService", "$transition$", "$q", "$mdDialog", "$scope", "textEditorService"];

    function TextPrescriptionFormCtrl(system, prescriptionService, $transition$, $q, $mdDialog, $scope, textEditorService) {
        let vm = this;

        let dateFormat = system['date_format'].js;

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
        let currentCopy = _.get($transition$.params('to'), 'copy');

        vm.$onInit = init;

        vm.saveModel = saveModel;
        vm.savePrescription = saveTextPrescription;
        vm.signPrescription = signPrescription;
        vm.unlockPrescription = unlockPrescription;

        vm.deleteItem = prescriptionService.deleteItem;
        vm.prePrint = prePrint;

        vm.cancel = goBack;

        function init() {
            prescriptionService.prescriptionType.next({type: 'TextPrescription'});
            prescriptionService.fileManager.next(false);

            prescriptionService.itemActive.next({id: -1, type: null});
            prescriptionService.itemDuplicated.next({id: -1, type: null});
            prescriptionService.itemEdited.next({id: currentPrescription, type: 'TextPrescription'});

            let promise = [];

            if (!_.isNull(currentCopy)) {
                prescriptionService.itemDuplicated.next({id: currentCopy, type: 'TextPrescription'});
                promise.push(prescriptionService.getTextPrescription(currentCopy))
            } else if (!_.isNull(currentPrescription)) promise.push(prescriptionService.getTextPrescription(currentPrescription))
            else promise.push(createEmptyTextPrescription());

            vm.promise = $q.all(promise).then(success);

            vm.editorName = _.uniqueId('text-prescription-');
            vm.editorConfigKey = "text_prescription";
            vm.currentPatient = currentPatient;
            vm.showFileManager = false;

            vm.editorOpts = {
                patient: currentPatient
            }

            function success(data) {
                let actualData = _.head(data)
                vm.prescription = _.isNull(currentCopy) ? actualData : _.chain(actualData).pick(['content', 'indication'])
                    .assign({
                        visit: {id: currentVisit},
                        prescription_date: moment().format(dateFormat)
                    })
                    .value()

                if (_.isNull(currentCopy)) {
                    vm.editModel = actualData['is_model'];

                    prescriptionService.prescriptionType.next({
                        type: 'TextPrescription',
                        model: vm.editModel
                    })
                }
            }

            let itemDeletedSubscription = prescriptionService.itemDeleted.subscribe(itemDeleted);
            let itemAddedSubscription = prescriptionService.addTextPrescriptionSubject.subscribe(itemAdded);
            let fileManagerSubscription = prescriptionService.fileManager.subscribe(next => {
                vm.showFileManager = !!next;
            });

            function itemDeleted(item) {
                if (_.isEqual(item, {id: currentPrescription, prescription_type: 'TextPrescription'})) {
                    prescriptionService.listState();
                }
            }

            function itemAdded(id) {
                vm.promise = prescriptionService.getTextPrescription(id)
                    .then(prescription => {
                        vm.prescription.content = `${vm.prescription.content}\n${prescription.content}`;
                    });
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                itemAddedSubscription.unsubscribe();
                fileManagerSubscription.unsubscribe();
            }
        }

        function createEmptyTextPrescription() {
            return {
                content: "",
                visit: {id: currentVisit},
                prescription_date: moment().format(dateFormat)
            }
        }

        function saveTextPrescription(quit) {
            return prescriptionService.saveTextPrescription(vm.prescription, currentPatient)
                .then(success)

            function success(data) {
                if (vm.editModel) prescriptionService.modelAdded.next(data);
                else prescriptionService.itemAdded.next(data);

                if (quit === "list") return prescriptionService.listState();
                if (quit === "back") return goBack();

                if (_.isNull(currentPrescription)) {
                    vm.prescription = data;
                    currentPrescription = data.id;
                    prescriptionService.refreshState(data);
                }
            }
        }

        function saveModel($event) {
            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.TextPrescription.title_validation')
                .placeholder('title')
                .targetEvent($event);

            $mdDialog.show(confirm).then(success);

            function success(data) {
                // TODO need to import the model when the editor is fully fixed
                let model = _.assign(_.pick(vm.prescription, ['content', 'indication']), {title: data});

                prescriptionService.saveTextPrescription(model)
                    .then(success);

                function success(data) {
                    prescriptionService.modelAdded.next(data);
                }
            }
        }

        function prePrint() {
            let deferred = $q.defer();

            textEditorService
                .getEditor(vm.editorName)
                .compileContent()
                .then(() => {
                    saveTextPrescription()
                        .then(() => {
                            deferred.resolve(false);
                        });
                });

            return deferred.promise;
        }

        function goBack() {
            return window.history.back();
        }

        function toggleLock(lock) {
            vm.prescription.is_locked = lock;

            return vm.prePrint();
        }

        function signPrescription() {
            return toggleLock(true);
        }

        function unlockPrescription() {
            return toggleLock(false);
        }

    }

})();
