/**
 * Created by BETALOS on 06/05/2016.
 */
(function () {

    'use strict';

    class SearchModalCtrl {
        constructor(modalService, $element, $attrs) {
            this.element = $element;
            this.modalService = modalService;

            this.modalId = $attrs['modalId'];
            this.subject = `${this.componentId}.${this.modalId}`;
            this.componentId = !$attrs['componentId'] ? $attrs['componentId'] : "default";

            this.choose = this.choose ? this.choose : false;
            this.remove = this.remove ? this.remove : _.noop;
            this.query = this.query ? this.query : _.noop;
            this.callback = this.callback ? this.callback : _.noop;
            this.selectOnly = this.selectOnly ? this.selectOnly : false;
        }

        static get $inject() {
            return ["modalService", "$element", "$attrs"];
        }

        $onInit() {
            this.element.parent().addClass("layout-align-start-center");
            this.modalSubscription = this.modalService.subject.subscribe(msg => this.modalCallBack(msg));
        }

        $onDestroy() {
            if (this.modalSubscription) this.modalSubscription.unsubscribe();
        }

        show(ev) {
            if (_.isFunction(this.query)) this.modalService.showModal(this.modalId, this.query(), this.componentId, ev);
            else this.modalService.showModal(this.modalId, this.query, this.componentId, ev);
        }

        cancel() {
            this.remove();
            this.choose = false;
        }

        modalCallBack(msg) {
            if (msg.subject === this.subject) {
                this.choose = true;
                this.callback({patient: msg.data});
            }
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        let disabled = attrs['disabled'] ? ' ng-disabled="vm.disabled" ' : "";
        let fontIcon = attrs['fontIcon'] ? attrs['fontIcon'] : "mdi-magnify";

        elem.addClass("layout-row");
        return `
            <div class="mn-opts" flex="nogrow">
                <md-button class="md-raised md-primary" ng-click="vm.show($event)" aria-label="find model" ${disabled}>
                    <md-icon md-font-icon="${fontIcon}" md-font-set="mdi"></md-icon>
                </md-button>
                <md-button class="md-raised md-primary" ng-click="vm.cancel()" ng-if="vm.choose && !vm.selectOnly">
                    <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                </md-button>
            </div>
        `;
    }

    module.exports = {
        bindings: {
            remove: '&',
            callback: '&',
            query: '=?',
            selectOnly: '=?',
            choose: '=?',
            disabled: "<",
        },
        template: tpl, // or template
        controllerAs: "vm",
        controller: SearchModalCtrl,
    };

})();
