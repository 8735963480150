/**
 * Created by Amine 03/11/2017
 * used for a precise need, do not edit
 * (to show legacy biologie [from Medinet 1.1,soon to be removed biologyModule{V 1}])
 */
(function () {
    'use strict';

    mnLegacyBiologyTableCtrl.$inject = [];

    function mnLegacyBiologyTableCtrl() {
        let vm = this;

        vm.$onInit = init;

        function init() {
        }
    }

    module.exports = {
        controller: mnLegacyBiologyTableCtrl,
        controllerAs: "vm",
        bindings: {
            details: '=prescriptionDetails',
        },
        template: require('prescription/views/legacy-biology-table.tpl.html'), // or template,
    };

})();
