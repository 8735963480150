/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    class ActiveModule {
        constructor(configService) {
            this.priority = 0;
            this.restrict = "A";
            this.service = configService;
        }

        static create() {
            return new ActiveModule(...arguments);
        }

        link(scope, element, attrs) {
            const ignoreEmpty = scope.$eval(attrs['ignoreEmpty']);
            const behavior = _.get(attrs, 'behavior', 'remove');
            const key = scope.$eval(attrs['mnActiveModule']) || attrs['mnActiveModule'];
            const value = _.get(this.service, `activeModules.${key}`, ignoreEmpty);

            if (!value) {
                if (behavior === 'remove') element.remove();
                if (behavior === 'disabled') attrs.$set('disabled', false);
            }
        }
    }

    ActiveModule.create.$inject = ["configService"];

    module.exports = ActiveModule.create;

})();
