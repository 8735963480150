(function () {

    'use strict';

    module.exports = {
        controller: ProcedureSearchCtrl,
        controllerAs: "vm",
        template: require("shared/views/procedure-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };

    ProcedureSearchCtrl.$inject = ["$mdDialog"];

    function ProcedureSearchCtrl($mdDialog) {
        let vm = this;

        vm.selected = [];
        vm.cancel = $mdDialog.cancel;

        vm.selectProcedures = selectProcedures;
        vm.selectRow = selectRow;

        function selectProcedures() {
            $mdDialog.hide(vm.selected);
        }

        function selectRow(data) {
            vm.selected.push(data);
        }
    }

})();