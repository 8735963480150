(function () {

    const angular = require('angular');

    const mnPrescriptionItem = require("./components/prescription-item");
    const mnBiologyTable = require("./components/biology-table");
    const mnLegacyBiologyTable = require("./components/legacy-biology-table");


    const prescriptionService = require("./services/prescriptionService");

    const mnBiologyRow = require("./directives/biology-row");

    const TextPrescriptionFormCtrl = require("./controllers/TextPrescriptionFormCtrl");
    const PrescriptionListCtrl = require("./controllers/PrescriptionListCtrl");
    const PrescriptionViewCtrl = require("./controllers/PrescriptionViewCtrl");
    const PrescriptionContainerCtrl = require("./controllers/PrescriptionContainerCtrl");
    const MedicalPrescriptionFormCtrl = require("./controllers/MedicalPrescriptionFormCtrl");
    const BiologyPrescriptionFormCtrl = require("./controllers/BiologyPrescriptionFormCtrl");
    const ProcedurePrescriptionFormCtrl = require("./controllers/ProcedurePrescriptionFormCtrl");


    angular
        .module("medinet")

        .component("mnPrescriptionItem", mnPrescriptionItem)
        .component("mnBiologyTable", mnBiologyTable)
        .component("mnLegacyBiologyTable", mnLegacyBiologyTable)

        .service("prescriptionService", prescriptionService)

        .directive("mnBiologyRow", mnBiologyRow)

        .controller("TextPrescriptionFormCtrl", TextPrescriptionFormCtrl)
        .controller("PrescriptionListCtrl", PrescriptionListCtrl)
        .controller("PrescriptionViewCtrl", PrescriptionViewCtrl)
        .controller("PrescriptionContainerCtrl", PrescriptionContainerCtrl)
        .controller("MedicalPrescriptionFormCtrl", MedicalPrescriptionFormCtrl)
        .controller("BiologyPrescriptionFormCtrl", BiologyPrescriptionFormCtrl)
        .controller("ProcedurePrescriptionFormCtrl", ProcedurePrescriptionFormCtrl);


})();
